import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import $ from "jquery";

import { DataHelper } from "./../../Common/Helpers/data-helper.js";
import { ValidationHelper } from "./../../Common/Helpers/validation-helper.js";

import styles from "./login.css"
import "./../../../Styles/site.css"

function Login() {
    const productName = "Parent Student Portal";

    const [ossDomainState, setOssDomainState] = useState("");
    const [schoolCodeState, setSchoolCodeState] = useState("");

    const [schoolName, setSchoolName] = useState("");
    const [uiState, setUiState] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [userType, setUserType] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [usernameNew, setUsernameNew] = useState("");
    const [passwordNew, setPasswordNew] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [assoc, setAssoc] = useState({ assocId: 0, assocType: "" });
    const [roles, setRoles] = useState([]);

    const [accessKey, setAccessKey] = useState("");
    const [accessKeyValid, setAccessKeyValid] = useState(false);

    const [statusLogin, setStatusLogin] = useState("");
    const [statusSignup, setStatusSignup] = useState("");
    const [statusAccessKey, setStatusAccessKey] = useState("");

    const [strict, setStrict] = useState(true);

    useEffect(getSettings, []);
    useEffect(getOssConfig, []);
    useEffect(getRoles, []);

    function getRoles() {
        $.ajax({
            url: "/user/getroles",
            method: "GET",
            contentType: "application/json; charset=utf-8"
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                setRoles(response);
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
        });
    }

    function getOssConfig() {
        DataHelper.getOssConfig(function (ossConfig) {
            setOssDomainState(ossConfig.ossDomain);
            getSchoolCode(ossConfig.ossDomain);
        });
    }

    function getSchoolCode(ossDomain) {
        DataHelper.getSchoolCode(function (schoolCode) {
            setSchoolCodeState(schoolCode);
            getSchool(ossDomain, schoolCode);
        });
    }    

    function getSchool(ossDomain, schoolCode) {
        $.ajax({
            url: ossDomain + "/api/schools/" + schoolCode,
            method: "GET",
            contentType: "application/json; charset=utf-8",
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                setSchoolName(response.name);
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
        });
    }

    function getSettings() {
        $.ajax({
            url: "/school/getsettings",
            method: "GET",
            contentType: "application/json; charset=utf-8",
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                setStrict(response.strict);
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
        });
    }

    function isAccessKeyValid(e) {
        e.preventDefault();

        setUiState("validatingAccessKey");

        $.ajax({
            url: ossDomainState + "/api/accessKeys/validate?accessKey=" + accessKey,
            method: "GET",
            contentType: "application/json; charset=utf-8",
            beforeSend: function (xhr) {
                xhr.setRequestHeader("SchoolCode", schoolCodeState);
            }
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                setAccessKeyValid(response.isValid);
                if (response.isValid) {
                    let assocType = "";
                    if (response.associatedType.toUpperCase() === "PARENT")
                        assocType = "Parent";
                    else if (response.associatedType.toUpperCase() === "STUDENT")
                        assocType = "Student";

                    setAssoc({
                        assocId: response.associatedTypeRefId,
                        assocType: assocType
                    });

                    getRoleByName(assocType);
                }
                else {
                    setStatusAccessKey("Invalid access key.");
                    setUiState("");
                }
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
            setUiState("");
        });
    }

    function getRoleByName(roleName) {
        let roleId = "";
        const role = roles.find(r => r.name.toUpperCase() === roleName.toUpperCase());
        if (typeof role !== "undefined")
            roleId = role.id;
        return roleId;
    }

    function showSignIn() {
        const classes = {
            signUpContainer: styles["signup-container"],
            signInContainer: styles["signin-container"],
            welcomeContainer: styles["welcome-container"]
        };

        $("." + classes.signUpContainer).css("display", "none");
        $("." + classes.signInContainer).css("display", "block");
        $("." + classes.welcomeContainer).css("display", "block");
    }

    function showSignUp() {
        const classes = {
            signUpContainer: styles["signup-container"],
            signInContainer: styles["signin-container"],
            welcomeContainer: styles["welcome-container"]
        };

        $("." + classes.signUpContainer).css("display", "block");
        $("." + classes.signInContainer).css("display", "none");
        $("." + classes.welcomeContainer).css("display", "none");
    }

    function signIn(e) {
        e.preventDefault();

        setUiState("loggingIn");

        if (validFields("loggingIn")) {
            const urlParams = new URLSearchParams(window.location.search);
            const returnUrl = urlParams.get("ReturnUrl");

            const data = {
                username: username,
                password: password,
                returnUrl: returnUrl ?? ""
            };

            $.ajax({
                url: "/account/signin",
                method: "POST",
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                data: JSON.stringify(data)
            })
            .done((response) => {
                if (response.success) {
                    window.location.href = response.returnUrl;
                }
                else {
                    setStatusLogin(response.errorMessage);
                }
            })
            .fail((xhr, textStatus, errorThrown) => {
                console.log(errorThrown);
                setUiState("");
            });
        }
        else {
            setUiState("");
        }
    }

    function signUp(e) {
        e.preventDefault();

        setUiState("signingUp");

        if (validFields("signingUp")) {

            const data = {
                firstName: firstName,
                lastName: lastName,
                emailAddress: emailAddress,
                username: usernameNew,
                password: passwordNew,
                role: {
                    id: function () {
                        let roleId = 0;
                        if (strict)
                            roleId = getRoleByName(assoc.assocType);
                        else
                            roleId = getRoleByName(userType);
                        return roleId;
                    }()
                },
                accessKey: accessKey,
                ossAssociatedRefId: assoc.assocId,
                ossAssociatedRefType: assoc.assocType
            };

            $.ajax({
                url: "/account/signup",
                method: "POST",
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                data: JSON.stringify(data)
            })
            .done((response) => {
                if (response.success) {
                    if (strict)
                        useAccessKey(response.userId, "/enrollment/index");
                    else
                        window.location.href = "/enrollment/index";
                }
                else {
                    setStatusSignup(response.errorMessage);
                    setUiState("");
                }
            })
            .fail((xhr, textStatus, errorThrown) => {
                console.log(errorThrown);
                setUiState("");
            });
        }
        else {
            setUiState("");
        }
    }

    function validFields(passedUiState) {
        let isValid = true;

        let error = "";
        if (passedUiState === "loggingIn") {
            if (username.trim() === "") {
                error = "Please enter your username.";
                isValid = false;
            }
            else if (password.trim() === "") {
                error = "Please enter your password.";
                isValid = false;
            }
        }
        else if (passedUiState === "signingUp") {
            if (firstName.trim() === "") {
                error = "Please enter your first name.";
                isValid = false;
            }
            else if (lastName.trim() === "") {
                error = "Please enter your last name.";
                isValid = false;
            }
            else if (emailAddress.trim() === "") {
                error = "Please enter your email address.";
                isValid = false;
            }
            else if (!ValidationHelper.isValidEmailAddress(emailAddress.trim())) {
                error = "Please enter a valid email address.";
                isValid = false;
            }
            else if (usernameNew.trim() === "") {
                error = "Please enter your username.";
                isValid = false;
            }
            else if (passwordNew.trim() === "") {
                error = "Please enter your password.";
                isValid = false;
            }
            else if (confirmPassword.trim() === "") {
                error = "Please confirm your password.";
                isValid = false;
            }
        }

        return isValid;
    }

    function useAccessKey(userId, returnUrl) {
        let data = {
            accessKey: accessKey,
            shouldUse: true
        };

        $.ajax({
            url: ossDomainState + "/api/accessKeys/use",
            method: "POST",
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(data),
            beforeSend: function (xhr) {
                xhr.setRequestHeader("SchoolCode", schoolCodeState);
            }
        })
        .done((response, textStatus, xhr) => {
            if (textStatus === "success") {
                window.location.href = returnUrl;
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            console.log(errorThrown);
            setUiState("");
        });
    }

    return (
        <div>
            <div styleName="welcome-container">
                <span styleName="welcome-container--welcome">Welcome</span>
                <span styleName="welcome-container--school-name">{schoolName.slice(schoolName.length - 1) !== 's' ? schoolName + "'s" : schoolName + "'"}</span>
                <span styleName="welcome-container--app-name">{productName}</span>
            </div>

            <div styleName="signin-container">
                <form onSubmit={signIn}>
                    <input type="text" styleName="login-form__input-text" value={username} onChange={e => setUsername(e.target.value)} name="username" placeholder="Username" required autoFocus disabled={uiState === "signingIn"} />
                    <input type="password" styleName="login-form__input-text" value={password} onChange={e => setPassword(e.target.value)} name="password" placeholder="Password" required disabled={uiState === "signingIn"} />
                    <button type="submit" styleName="signin--button signin--button--highlight" onClick={signIn}>Sign in</button>
                    <span styleName="login-form__message">{statusLogin}</span>
                </form>

                <div styleName="signin-signup-container">
                    <span styleName="signin-signup--dont-have-account">Don't have an account yet?</span>
                    <span styleName="signin-signup--signup action-link" onClick={showSignUp}>Sign up</span>
                </div>
                <div styleName="forgot-link-container">
                    <a href="/account/forgot" styleName="forgot-link">Forgot username or password?</a>
                </div>
            </div>

            <div styleName="signup-container">
                {
                    (!strict || accessKeyValid) ?
                        (
                            <Fragment>
                                <form onSubmit={signUp}>
                                    <span styleName="signup-container--title">Sign Up for a New Account</span>
                                    {
                                        !strict &&
                                        <select styleName="login-form__input-text" name="type" id="type" onChange={e => setUserType(e.target.value)} defaultValue="" required disabled={uiState === "signingUp"}>
                                            <option disabled value="">Select a Type</option>
                                            <option value={"Parent"}>{"Parent"}</option>
                                            <option value={"Student"}>{"Student"}</option>
                                        </select>
                                    }
                                    <input type="text" styleName="login-form__input-text" value={firstName} onChange={e => setFirstName(e.target.value)} name="firstName" placeholder="First Name" required autoFocus disabled={uiState === "signingUp"} />
                                    <input type="text" styleName="login-form__input-text" value={lastName} onChange={e => setLastName(e.target.value)} name="lastName" placeholder="Last Name" required disabled={uiState === "signingUp"} />
                                    <input type="email" styleName="login-form__input-text" value={emailAddress} onChange={e => setEmailAddress(e.target.value)} name="emailAddress" placeholder="Email Address" required disabled={uiState === "signingUp"} />
                                    <input type="text" styleName="login-form__input-text" value={usernameNew} onChange={e => setUsernameNew(e.target.value)} name="username" placeholder="Username" required disabled={uiState === "signingUp"} />
                                    <input type="password" styleName="login-form__input-text" value={passwordNew} onChange={e => setPasswordNew(e.target.value)} name="password" placeholder="Password" required disabled={uiState === "signingUp"} />
                                    <input type="password" styleName="login-form__input-text" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} name="confirmpassword" placeholder="Confirm Password" required disabled={uiState === "signingUp"} />
                                    <button type="submit" styleName="signup--button signup--button--highlight" onClick={signUp} disabled={uiState === "signingUp"}>Sign up</button>
                                    <span styleName="login-form__message">{statusSignup}</span>
                                </form>
                            </Fragment>
                        ) :
                        (
                            <div styleName="need-access-key-container">
                                <span>You'll need to contact the school to request an access key</span>
                                <div styleName="need-to-contact-school_container">
                                    <form onSubmit={isAccessKeyValid}>
                                        <input type="text" styleName="login-form__input-text need-to-contact-school" value={accessKey} onChange={e => setAccessKey(e.target.value)} name="accessKey" placeholder="Access Key" required autoFocus disabled={uiState === "validatingAccessKey"} />
                                        <button type="submit" styleName="signin--button signin--button--highlight" onClick={isAccessKeyValid} disabled={uiState === "validatingAccessKey"}>Submit</button>
                                        <span styleName="login-form__message">{statusAccessKey}</span>
                                    </form>
                                </div>
                            </div>
                        )
                }

                <div styleName="signup-signin-container">
                    <span styleName="signup-signin--already-have-account">Already have an account?</span>
                    <span styleName="action-link signup-signin--signin" onClick={showSignIn}>Sign in</span>
                </div>
            </div>
        </div>
    );
}

ReactDOM.render(
    <Login />,
    document.getElementById("root")
);